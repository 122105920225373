<template>
  <div class="place-list">
    <div class="place-list_header" v-if="header">
      <div class="place-list_title">Адреса</div>
      <div class="place-list_add" @click="$eventBus.emit('place-modal-open')">
        <div class="icon icon-new"></div>
        <span>Добавить</span>
      </div>
    </div>
    <ul v-if="loading">
      <li v-for="i in 3" :key="i">
        <v-skeleton-loader type="list-item-avatar-two-line"/>
      </li>
    </ul>
    <div class="place-list-container" v-else>
      <ul v-if="places.length">
        <li v-for="place in places" :key="place.id">
          <div
            class="place-list_item"
            @click="$eventBus.emit('place-modal-open', {id: place.id})"
          >
            <div class="place-list_item_media">
              <div class="icon icon-geo"></div>
            </div>
            <div class="place-list_item_inner">
              <div class="place-list_item_title">{{ place.title }}</div>
              <div class="place-list_item_subtitle">{{ place.address }}</div>
            </div>
            <div class="place-list_item_after">
              <div class="icon icon-trash" @click.stop="removePlaceConfirmOpen(place.id)"></div>
              <div class="icon icon-pencil"></div>
            </div>
          </div>
        </li>
      </ul>
      <NoResults v-else>
        <template v-slot:title>Ничего не найдено</template>
        <template v-slot:text>
          Простота создания, быстрота наполнения, фокусировка и результаты
        </template>
      </NoResults>
    </div>
    <Confirm ref="confirm-remove" message="Удалить адрес?" @accept="removePlace"/>
  </div>
</template>

<script>
export default {
  name: 'PlaceList',
  props: {
    header: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      places: [],
      loading: false,
    };
  },
  methods: {
    removePlaceConfirmOpen(id) {
      this.placeIdForRemoving = id;
      this.$refs['confirm-remove'].open();
    },
    removePlace() {
      this.$server.request2(`place/remove/${this.placeIdForRemoving}`, {}, () => {
        this.loadList();
      });
    },
    loadList() {
      this.loading = true;
      this.$server.request2('place/get', {}, (data) => {
        this.loading = false;
        this.places = data.response;
      }, () => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadList();
  },
  created() {
    this.$eventBus.on('place-updated', () => {
      this.loadList();
    });
  },
  beforeDestroy() {
    this.$eventBus.off('place-updated');
  }
};
</script>

<style lang="scss">
.place-list {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  &_add {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 6px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid var(--border-color);
  }

  li {
    border-bottom: 1px solid var(--border-color);
  }

  &_item {
    cursor: pointer;
    display: flex;

    &_media {
      flex-shrink: 0;
      margin-right: 20px;
      padding: 12px 0;

      .icon {
        width: 54px;
        height: 54px;
        font-size: 24px;
        background: var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }

    &_inner {
      width: 100%;
      min-width: 0;
      padding: 18px 0;

      & > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
    }

    &_after {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > .icon {
        padding: 0 14px;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(var(--page-color-main-rgb), .25);

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .no-results-block {
    margin-top: 50px;
  }
}
</style>
