<template>
  <div class="page">
    <div class="page-content">
      <div class="page-header">
        <div class="page-title">Адреса</div>
        <div
          class="page-header-button default-button"
          @click="$eventBus.emit('place-modal-open')"
        >
          <div class="icon icon-new"></div>
          <span>Новый адрес</span>
        </div>
      </div>
      <PlaceList :header="false"/>
    </div>
  </div>
</template>

<script>
import PlaceList from '@/views/places/PlaceList.vue';

export default {
  name: 'Places',
  components: { PlaceList },
};
</script>
